<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:body>
      <b-row>
        <b-col>
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro.organization')"
            label-for="org_id"
            >
            <b-form-select
              plain
              v-model="search.org_id"
              :options="organizationList"
              id="org_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_division.division')"
            label-for="division_id"
            >
            <b-form-select
              plain
              v-model="search.far_division_id"
              :options="divisionList"
              id="division_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_district.district')"
            label-for="district_id"
            >
            <b-form-select
              plain
              v-model="search.far_district_id"
              :options="districtList"
              id="district_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_upazilla.upazilla')"
            label-for="upazilla_id"
            >
            <b-form-select
              plain
              v-model="search.far_upazilla_id"
              :options="upazilaList"
              id="upazilla_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('org_pro_union.union')"
            label-for="union_id"
            >
            <b-form-select
              plain
              v-model="search.far_union_id"
              :options="unionList"
              id="union_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('farmerOperator.testing_type')"
            label-for="testing_type_id"
            >
            <b-form-select
              plain
              v-model="search.testing_type_id"
              :options="testingTypeList"
              id="testing_type_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('farmerOperator.testing_type')"
            label-for="testing_type_id"
            >
            <b-form-select
              plain
              v-model="search.status"
              :options="waterTestingStatusList"
              id="testing_type_id"
              >
              <template v-slot:first>
                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
              </template>
            </b-form-select>
            </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('farmerOperator.farmer_id')"
            label-for="farmer_id"
            >
            <b-form-input
              plain
              v-model="search.farmer_id"
              id="farmer_id"
              >
            </b-form-input>
            </b-form-group>
        </b-col>
      </b-row>
      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('farmerOperator.water_testing_request') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
              {{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(division_name)="data">
                      <span>{{ data.item.ontime | formatDate }}</span>
                    </template>
                    <template v-slot:cell(waterTestingRequest_name)="data">
                      <span>{{ data.item.offtime | formatDate }}</span>
                    </template>
                    <!-- <template v-slot:cell(status)="data">
                      {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                    </template> -->
                    <template v-slot:cell(action)="data">
                      <b-button class="mr-1" title="View Complain" v-b-modal.modal-detail variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                      <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="testClick"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="testId" :key="testId"/>
      </p>
    </b-modal>
    <b-modal id="modal-detail" size="lg" :title="detailTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <DetailModal :id="testId" :key="testId"/>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import FormV from './Form'
import DetailModal from './DetailModal'
// import RestApi, { commonServiceBaseUrl } from '@/config/api_config'
import { waterTestingRequestList, waterTestingRequestToggleStatus } from '../../api/routes'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'

export default {
  name: 'UiDataTable',
  components: {
    FormV, DetailModal
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        org_id: '0',
        far_division_id: '0',
        far_district_id: '0',
        far_upazilla_id: '0',
        far_union_id: '0',
        status: '0',
        farmer_id: '',
        testing_type_id: '0'
      },
      testId: 0,
      pumpInformationDropdownList: [],
      rows: [],
      projectList: [],
      districtList: [],
      upazilaList: [],
      unionList: []
    }
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    testingTypeList: function () {
      const ObjectData = this.$store.state.commonObj.testingTypeList
      return ObjectData.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.name_bn }
          } else {
            return { value: obj.id, text: obj.name }
          }
        })
    },
    waterTestingStatusList: function () {
      const ObjectData = this.$store.state.commonObj.waterTestingStatusList
      return ObjectData.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.name_bn }
          } else {
            return { value: obj.id, text: obj.name }
          }
        })
    },
    formTitle () {
       return (this.testId === 0) ? this.$t('farmerOperator.water_testing_request_entry') : this.$t('farmerOperator.water_testing_request') + ' ' + this.$t('globalTrans.modify')
    },
    detailTitle () {
       return this.$t('farmerOperator.water_testing_request_detail')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    listData () {
      return this.$store.state.FarmerOperatorPanel.waterTestingRequests
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('org_pro.organization'), class: 'text-left' },
          { label: this.$t('farmerOperator.applicant_name'), class: 'text-left' },
          { label: this.$t('farmerOperator.sample_number'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'name_bn' },
          { key: 'sample_number' },
          { key: 'testing_status_name_bn' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'name' },
          { key: 'sample_number' },
          { key: 'testing_status_name' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    },
    'search.org_id': function (newVal, oldVal) {
      this.getProjectlist(newVal)
    },
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            if (this.search.division_id) {
              this.districtList = this.getDistrictList()
            }
            if (this.search.district_id) {
              this.upazilaList = this.getUpazilaList()
            }
            if (this.search.upazilla_id) {
              this.unionList = this.getUnionList()
            }
            if (this.search.org_id) {
              this.getProjectlist()
            }
        }
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    core.index()
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    details (item) {
      this.testId = item.id
    },
    testClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length,
        division_id: '',
        district_id: '',
        upazilla_id: '',
        waterTestingRequest_name: '',
        waterTestingRequest_name_bn: ''
      }
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.testId = item.id
    },
    toggleStatus (item) {
      RestApi.deleteData(irriSchemeServiceBaseUrl, `${waterTestingRequestToggleStatus}/${item.id}`).then(response => {
        if (response.success) {
          this.$store.dispatch('FarmerOperatorPanel/togglewaterTestingRequestStatus', item)
          this.$toast.success({
            title: 'Success',
            message: 'Data Updated Successfully',
            color: '#D6E09B'
          })
        } else {
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
      })
    },
    remove (item) {
      this.$swal({
        title: this.$t('globalTrans.statusChangeMsg'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.toggleStatus(item)
        }
      })
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      RestApi.getData(irriSchemeServiceBaseUrl, waterTestingRequestList, params).then(response => {
        if (response.success) {
          this.getRelationalData(response.data)
          // this.$store.dispatch('FarmerOperatorPanel/addwaterTestingRequestList', response.data.data)
          // this.paginationData(response.data)
        }
      })
      //
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      const organizationList = this.$store.state.commonObj.organizationProfileList
      const divisionList = this.$store.state.commonObj.divisionList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList
      const unionList = this.$store.state.commonObj.unionList
      const testingTypeList = this.$store.state.commonObj.testingTypeList
      const waterTestingStatusList = this.$store.state.commonObj.waterTestingStatusList
      const waterTestingRequestData = data.data.map(item => {
        const orgObject = organizationList.find(oganization => oganization.value === item.org_id)
        const divisionObject = divisionList.find(division => division.value === item.far_division_id)
        const districtObject = districtList.find(district => district.value === item.far_district_id)
        const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.far_upazilla_id)
        const unionObject = unionList.find(union => union.value === item.far_union_id)
        const testingTypeObject = testingTypeList.find(testingType => testingType.id === item.testing_type_id)
        const statusListObject = waterTestingStatusList.find(testingStatus => testingStatus.id === item.status)
        const orgData = {
          org_name: orgObject.text,
          org_name_bn: orgObject.text_bn
        }
        const divisionData = {
          division_name: divisionObject.text,
          division_name_bn: divisionObject.text_bn
        }
        const districtData = {
          district_name: districtObject.text,
          district_name_bn: districtObject.text_bn
        }
        const upazillaData = {
          upazilla_name: upazilaObject.text,
          upazilla_name_bn: upazilaObject.text_bn
        }
        const unionData = {
          union_name: unionObject.text,
          union_name_bn: unionObject.text_bn
        }
        const TestingTypeData = {
          testing_type_name: testingTypeObject.name,
          testing_type_name_bn: testingTypeObject.name_bn
        }
        const waterTestingData = {
          testing_status_name: statusListObject.name,
          testing_status_name_bn: statusListObject.name_bn
        }
        return Object.assign({}, item, orgData, divisionData, districtData, upazillaData, unionData, TestingTypeData, waterTestingData)
      })
      this.$store.dispatch('FarmerOperatorPanel/addwaterTestingRequestList', waterTestingRequestData)
      this.paginationData(data)
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    }
  }
}
</script>

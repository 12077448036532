<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
              <b-form  @submit.prevent="handleSubmit(register)" >
            <b-row>
              <b-col lg="6" sm="12">
                <ValidationProvider name="organization" vid="org_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="organization"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                     {{ $t('org_pro.organization') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="waterTestingRequest.org_id"
                      :options="organizationList"
                      id="org_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Tesing Type Id" vid="testing_type_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="testing_type_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                     {{ $t('farmerOperator.testing_type') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="waterTestingRequest.testing_type_id"
                      :options="testingTypeList"
                      id="testing_type_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="name" vid="name" rule="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="name"
                    slot-scope="{ valid, errors }"
                  >
                   <template v-slot:label>
                     {{ $t('farmerOperator.applicant_name') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="name"
                      v-model="waterTestingRequest.name"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="name" vid="name_bn">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="name_bn"
                    slot-scope="{ valid, errors }"
                  >
                   <template v-slot:label>
                     {{ $t('farmerOperator.applicant_name_bn') }}
                    </template>
                    <b-form-input
                      id="name_bn"
                      v-model="waterTestingRequest.name_bn"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Division" vid="far_division_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="division"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                     {{ $t('org_pro_division.division') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="waterTestingRequest.far_division_id"
                      :options="divisionList"
                      id="far_division_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="District" vid="far_district_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="district"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                     {{ $t('org_pro_district.district') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="waterTestingRequest.far_district_id"
                      :options="districtList"
                      id="far_district_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Upazila" vid="far_upazilla_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="upazila"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                     {{ $t('org_pro_upazilla.upazilla') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="waterTestingRequest.far_upazilla_id"
                      :options="upazilaList"
                      id="upazila_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="6" sm="12">
                <ValidationProvider name="union" vid="far_union_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="far_union_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                     {{ $t('org_pro_union.union') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="waterTestingRequest.far_union_id"
                      :options="unionList"
                      id="far_union_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Village" vid="far_village" rule="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="far_village"
                    slot-scope="{ valid, errors }"
                  >
                   <template v-slot:label>
                     {{ $t('farmerOperator.village') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="far_village"
                      v-model="waterTestingRequest.far_village"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Village (bn)" vid="far_village_bn">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="far_village_bn"
                    slot-scope="{ valid, errors }"
                  >
                   <template v-slot:label>
                     {{ $t('farmerOperator.village_bn') }}
                    </template>
                    <b-form-input
                      id="far_village_bn"
                      v-model="waterTestingRequest.far_village_bn"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="from_date" vid="from_date" rule="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="from_date"
                    slot-scope="{ valid, errors }"
                  >
                   <template v-slot:label>
                     {{ $t('globalTrans.from_date') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="from_date"
                      type="date"
                      v-model="waterTestingRequest.from_date"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="to_date" vid="to_date" rule="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="3"
                    label-for="to_date"
                    slot-scope="{ valid, errors }"
                  >
                   <template v-slot:label>
                     {{ $t('globalTrans.to_date') }} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="to_date"
                      type="date"
                      v-model="waterTestingRequest.to_date"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { waterTestingRequestStore, waterTestingRequestUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      waterTestingRequest: {
        org_id: '0',
        testing_type_id: '0',
        far_division_id: '0',
        far_district_id: '0',
        far_upazilla_id: '0',
        far_union_id: '0',
        name: '',
        name_bn: '',
        far_village: '',
        far_village_bn: '',
        from_date: '',
        to_date: ''
      },
      districtList: [],
      upazilaList: [],
      unionList: [],
      projectList: [],
      pumpTypeList: [],
      pumpInformationList: [],
      pumpInformationDropdownList: []
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getwaterTestingRequestData()
      Object.freeze(tmp)
      this.waterTestingRequest = tmp
    }
  },
  mounted () {
    core.index()
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    testingTypeList: function () {
      const listObject = this.$store.state.commonObj.testingTypeList
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.id, text: obj.name_bn }
        } else {
          return { value: obj.id, text: obj.name }
        }
      })
      return tmpList
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    }
  },
  watch: {
    'waterTestingRequest.org_id': function (newVal, oldVal) {
      this.getProjectlist(newVal)
      this.getPumplist(newVal)
    },
    'waterTestingRequest.far_division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'waterTestingRequest.far_district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'waterTestingRequest.far_upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    },
    'waterTestingRequest.far_union_id': function (newVal, oldVal) {
      this.getPumpInformationList(newVal)
    }
  },
  methods: {
    getwaterTestingRequestData () {
      return this.$store.state.FarmerOperatorPanel.waterTestingRequests.find(item => item.id === this.id)
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.waterTestingRequest.id) {
        result = await RestApi.putData(irriSchemeServiceBaseUrl, `${waterTestingRequestUpdate}/${this.id}`, this.waterTestingRequest)
      } else {
        result = await RestApi.postData(irriSchemeServiceBaseUrl, waterTestingRequestStore, this.waterTestingRequest)
      }

      if (result.success && this.waterTestingRequest.id) {
        this.$store.dispatch('FarmerOperatorPanel/updatewaterTestingRequest', this.waterTestingRequest)
      } else if (result.success && !this.waterTestingRequest.id) {
        loadinState.listReload = true
      }
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getProjectlist (orgId) {
      RestApi.getData(irriSchemeServiceBaseUrl, 'project/list').then(response => {
        const objectData = response.data.data.filter(project => project.org_id === orgId)
        this.projectList = objectData.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.project_name_bn }
          } else {
            return { value: obj.id, text: obj.project_name }
          }
        })
      })
    },
    // projectList (orgId) {
    //   const projectList = this.$store.state.IrriConfig.commonObj.projectList
    //   if (orgId) {
    //     return projectList.filter(pumpType => (project => project.org_id === orgId && project.status === 0))
    //   }
    //   return projectList
    // },
    getPumplist (orgId) {
      RestApi.getData(irriSchemeServiceBaseUrl, 'pump-type/list-all').then(response => {
        const objectData = response.data.filter(pumpType => pumpType.org_id === orgId)
        this.pumpTypeList = objectData.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.pump_type_name_bn }
          } else {
            return { value: obj.id, text: obj.pump_type_name }
          }
        })
      })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)

      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }

      return unionList
    },
    getPumpInformationList (unionId) {
      RestApi.getData(irriSchemeServiceBaseUrl, 'pump-information/list-all').then(response => {
        const objectData = response.data.filter(pumpInfo => pumpInfo.far_union_id === unionId)
        this.pumpInformationList = objectData.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { id: obj.id, mouza_no: obj.mouza_no }
          } else {
            return { id: obj.id, text: obj.pump_id }
          }
        })

        this.pumpInformationDropdownList = objectData.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.pump_id, text: obj.pump_id }
          } else {
            return { value: obj.pump_id, text: obj.pump_id }
          }
        })
      })
    }
  }
}
</script>

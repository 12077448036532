<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table striped small bordered hover :items="columns"  thead-class="hidden_header"></b-table>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
// import RestApi, { commonServiceBaseUrl } from '../../../../../config/api_config'
// import { getCommitteeList, getCommitteeReport } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getWaterTestingRequest()
      Object.freeze(tmp)
      this.waterTestingRequest = tmp
    }
  },
  data () {
    return {
      waterTestingRequest: '',
      slOffset: 1
    }
  },
  computed: {
    columns () {
      if (this.$i18n.locale === 'bn') {
        return [
          { key: this.$t('complain.organization'), val: this.waterTestingRequest.org_name_bn, key1: this.$t('farmerOperator.applicant_name'), val1: this.waterTestingRequest.name_bn },
          { key: this.$t('complain.division'), val: this.waterTestingRequest.division_name_bn, key1: this.$t('complain.district'), val1: this.waterTestingRequest.district_name_bn },
          { key: this.$t('complain.upazila'), val: this.waterTestingRequest.upazilla_name_bn, key1: this.$t('complain.union'), val1: this.waterTestingRequest.union_name_bn },
          { key: this.$t('farmerOperator.village'), val: this.waterTestingRequest.far_village_bn, key1: this.$t('globalTrans.from_date'), val1: this.waterTestingRequest.from_date },
          { key: this.$t('farmerOperator.sample_number'), val: this.$n(this.waterTestingRequest.sample_number), key1: this.$t('globalTrans.to_date'), val1: this.waterTestingRequest.to_date },
          { key: this.$t('farmerOperator.testing_type'), val: this.waterTestingRequest.testing_type_name_bn, key1: this.$t('farmerOperator.sample_serial'), val1: this.waterTestingRequest.sample_serial },
          { key: this.$t('globalTrans.status'), val: this.waterTestingRequest.testing_status_name_bn, key1: this.$t('farmerOperator.farmer_id'), val1: this.$n(this.waterTestingRequest.farmer_id) }
        ]
      } else {
          return [
            { key: this.$t('complain.organization'), val: this.waterTestingRequest.org_name, key1: this.$t('farmerOperator.applicant_name'), val1: this.waterTestingRequest.name },
            { key: this.$t('complain.division'), val: this.waterTestingRequest.division_name, key1: this.$t('complain.district'), val1: this.waterTestingRequest.district_name },
            { key: this.$t('complain.upazila'), val: this.waterTestingRequest.upazilla_name, key1: this.$t('complain.union'), val1: this.waterTestingRequest.union_name },
            { key: this.$t('farmerOperator.village'), val: this.waterTestingRequest.far_village, key1: this.$t('globalTrans.from_date'), val1: this.waterTestingRequest.from_date },
            { key: this.$t('farmerOperator.sample_number'), val: this.waterTestingRequest.sample_number, key1: this.$t('globalTrans.to_date'), val1: this.waterTestingRequest.to_date },
            { key: this.$t('farmerOperator.testing_type'), val: this.waterTestingRequest.testing_type_name, key1: this.$t('farmerOperator.sample_serial'), val1: this.waterTestingRequest.sample_serial },
            { key: this.$t('globalTrans.status'), val: this.waterTestingRequest.testing_status_name, key1: this.$t('farmerOperator.farmer_id'), val1: this.waterTestingRequest.farmer_id }
          ]
      }
    }
  },
  methods: {
    getWaterTestingRequest () {
      return this.$store.state.FarmerOperatorPanel.waterTestingRequests.find(item => item.id === this.id)
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
